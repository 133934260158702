// ES5 way
// exports.onClientEntry = () => {
// ES6 way
// export const onClientEntry = () => {
//   // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
//   if (!(`IntersectionObserver` in window)) {
//     import(`intersection-observer`)
//     console.log(`# IntersectionObserver is polyfilled!`)
//   }
// }
/* 
import React from "react"
import { IdentityContextProvider } from "react-netlify-identity"

export const wrapRootElement = ({ element }) => {
  return (
 // supply the url of your Netlify site instance with Identity enabled. VERY IMPORTANT
    <IdentityContextProvider url={"https://nextlegal.io/.netlify/identity"}>{element}</IdentityContextProvider>
  );
}

 */
import React from "react"
import {
    useNetlifyIdentity,
    IdentityContextProvider,
  } from "react-netlify-identity-widget"
  import "react-netlify-identity-widget/styles.css"

  export const wrapRootElement = ({ element }) => {
    return (
   // supply the url of your Netlify site instance with Identity enabled. VERY IMPORTANT
      <IdentityContextProvider url={"https://nextlegal.io"}>{element}</IdentityContextProvider>
    );
  }
