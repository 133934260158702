// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-agenda-js": () => import("./../../../src/pages/agenda.js" /* webpackChunkName: "component---src-pages-agenda-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-confirmation-js": () => import("./../../../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-init-js": () => import("./../../../src/pages/indexInit.js" /* webpackChunkName: "component---src-pages-index-init-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-indexcopy-js": () => import("./../../../src/pages/indexcopy.js" /* webpackChunkName: "component---src-pages-indexcopy-js" */),
  "component---src-pages-logingop-js": () => import("./../../../src/pages/logingop.js" /* webpackChunkName: "component---src-pages-logingop-js" */),
  "component---src-pages-logy-js": () => import("./../../../src/pages/logy.js" /* webpackChunkName: "component---src-pages-logy-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-producindex-js": () => import("./../../../src/pages/producindex.js" /* webpackChunkName: "component---src-pages-producindex-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */)
}

